import React from "react";
import Layout from "../components/layout/layout";
import { Link } from "gatsby";
import "./404.scss";

const NotFoundPage = () => {
  return (
    <Layout title="404" description="Page Not Found">
      <div className="error">
        <h1>Page Not Found</h1>
        <p>Oops, looks like you've found yourself in the abyss.</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
